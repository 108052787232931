import React from "react";
import pic1 from "../assets/images/1124.png";
import pic2 from "../assets/images/1142.png";
import pic3 from "../assets/images/1193.png";
const Team = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-6xl mx-auto px-4 py-20 ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className='flex self-center justify-center'>
            <h1 className="text-primary text-4xl md:text-7xl font-bold text-left  cursor-pointer pb-12">
              female team duh.
            </h1>
          </div>
          <div className='col-span-2'>
            <div className="flex border-b-2 border-primary pb-7 mb-7">
              <div>
                <img src={pic1} alt="" className='w-24 rounded-full' />
              </div>
              <div className='pl-5'>
                <h1 className='text-xl font-semibold'>DANIBABY17</h1>
                <p className='text-base pb-2 font-normal'>good girl</p>
                <p className='text-sm font-normal'>marketing, sales, mom, biz lead</p>
              </div>
            </div>
            <div className="flex border-b-2 border-primary pb-7 mb-7">
              <div>
                <img src={pic2} alt="" className='w-24 rounded-full' />
              </div>
              <div className='pl-5'>
                <h1 className='text-xl font-semibold'>DANIBABY17</h1>
                <p className='text-base pb-2 font-normal'>good girl</p>
                <p className='text-sm font-normal'>marketing, sales, mom, biz lead</p>
              </div>
            </div>
            <div className="flex border-b-2 border-primary pb-7 mb-7">
              <div>
                <img src={pic3} alt="" className='w-24 rounded-full' />
              </div>
              <div className='pl-5'>
                <h1 className='text-xl font-semibold'>DANIBABY17</h1>
                <p className='text-base pb-2 font-normal'>good girl</p>
                <p className='text-sm font-normal'>marketing, sales, mom, biz lead</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
