import React from "react";
import pic from "../assets/images/1142.png";

const Footer = () => {
  return (
    <div className="w-full bg-white">
      <div className="  ">
        <div className=" grid-cols-1 md:grid-cols-2 grid">
          <div>
            <img src={pic} alt="" />
          </div>
          <div className='flex justify-center self-center'>
            <div className="px-6 md:px-20 py-20 md:py-0">
              <h1 className=" text-4xl md:text-7xl font-bold text-primary   pb-6">
                moon with Us.
              </h1>
              <h3 className="text-black text-2xl uppercase font-medium">
                MAILING ADDRESS
              </h3>
              <p className="text-black text-2xl font-normal pb-10">
                123 Anywhere St. Any City, ST 12242
              </p>
              <h3 className="text-black text-2xl uppercase font-medium ">
                EMAIL ADDRESS
              </h3>
              <p className="text-black text-2xl font-normal pb-20">
                cryptonymphos69@gmail.com
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdXPaW3PuXckQbUpVuYbMw4RHNrtar4n1zdiCrJ9Fa0Did_SQ/viewform?usp=sf_link"
                className="text-white bg-primary px-8 md:px-14 py-4 md:py-7 uppercase text-xl  rounded-full transition-all hover:text-white font-semibold cursor-pointer"
              >
                Join WHITELIST
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
