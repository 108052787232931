import React from "react";
import Marquee from "react-fast-marquee";
import pic1 from "../assets/images/105.png";
import pic2 from "../assets/images/120.png";
import pic3 from "../assets/images/129.png";
import pic4 from "../assets/images/386.png";
import pic5 from "../assets/images/1124.png";
import pic6 from "../assets/images/1142.png";
import pic7 from "../assets/images/1193.png";
import pic8 from "../assets/images/1195.png";
import pic9 from "../assets/images/1278.png";
import pic10 from "../assets/images/2395.png";
import pic11 from "../assets/images/2612.png";
import pic12 from "../assets/images/4653.png";
import pic13 from "../assets/images/4967.png";
import pic14 from "../assets/images/5076.png";
import pic15 from "../assets/images/5714.png";
const Gallery = () => {
  return (
    <div className="w-full bg-white">
      <div className=" py-20">
        <h1 className="text-primary uppercase pb-4 text-7xl font-bold text-center  cursor-pointer">
          Gallery
        </h1>
        <div >
          <div className="mt-16  pb-20">
            <Marquee speed={80} gradient={false}>
              <div className="flex">
                <img src={pic1} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic2} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic3} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic4} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic5} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic6} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic7} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic8} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic9} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic10} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic11} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic12} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic13} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic14} alt="" className="w-60 rounded-full mx-2" />
                <img src={pic15} alt="" className="w-60 rounded-full mx-2" />
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
