import React from "react";
import {useWallet} from "use-wallet";

const Navbar = () => {
  const wallet = useWallet()
  const getShortWallet = (address)=>{
    return `${address.substring(0,4)}....${address.slice(address.length - 4)}`;

  }
  return (
    <div className="w-full bg-transparent">
      <div className="max-w-7xl mx-auto px-2">
        <div className="fixed left-0 right-0 top-0  z-50 py-10">
          <div className="flex justify-between">
            <div />
            <div className="flex self-center">
              <ul className="flex justify-between">
                <li className="px-2.5">
                  <a
                    href="https://discord.gg/7UeHVbkn6H"
                    className="text-primary bg-white px-5 py-2.5 rounded-full transition-all hover:text-primary font-semibold cursor-pointer"
                  >
                    Join Discord
                  </a>
                </li>
                <li className="px-2.5">
                  
                  <a className="text-primary bg-white px-5 py-2.5 rounded-full transition-all hover:text-primary font-semibold cursor-pointer"
               onClick={()=>{
                  wallet.connect()
              }}>
                {wallet.account?getShortWallet(wallet.account):"Connect Wallet"}
              </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
