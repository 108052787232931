import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";


const Roadmap = () => {

  return (
    <div className="w-full bg-black">
      <div className="max-w-6xl mx-auto py-20 px-4">
        <h1 className="text-primary text-3xl md:text-7xl font-bold text-center  cursor-pointer pb-10 md:pb-10">
          Road Map
        </h1>
        <div className="flex justify-center">
          <div className="">
            <VerticalTimeline>
              {roadmapData.map((items, index) => {
                return (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: "#F347BF",
                      color: "#fff"
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid  #F347BF"
                    }}
                    iconStyle={{background: "#F347BF", color: "#fff"}}
                    key={index}
                  >
                    <h3 className="text-white railway font-semibold text-3xl pb-3">
                      {items.title}
                    </h3>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;

const roadmapData = [
  {
    title: "Crypto Nympho's Roadmap 1"
  },
  {
    title: "The launch takes place in 3 rounds. "
  },
  {
    title:
      "Round 1 (presale) - Whitelist early access for first 1000 Crypto Nymphos."
  },
  {
    title: "Round 2 - Pre Sale Open for 69 minutes "
  },
  {
    title: "Round 3 - Public sale launch for everyone"
  },
  {
    title: "Once Whitelist Sale is complete "
  },
  {
    title:
      "$Nymphos Token will be created and an air drop will happen to whitelist minters. "
  },
  {
    title:
      "There are 69 super rare Nymphos 1% who are either zombies, aliens, or robots.  "
  },
  {
    title:
      "Launch Crypto Nymphos Merch Store 10% of profits will be donated to charities  "
  },
  {
    title:
      "Charity Donation $100,000 to a womens empowerment Charity of the Nymphos communities choice - will have a vote in discord once all Nymphos are minted."
  },
  {
    title:
      "Our own NFT platform will be developed which will allow NFTS to be sold on secondary market."
  }
];
