import React from "react";
import {useWallet} from "use-wallet";

const Header = () => {
  const wallet = useWallet()
  const getShortWallet = (address)=>{
    return `${address.substring(0,4)}....${address.slice(address.length - 4)}`;

  }
  return (
    <div className="w-full header">
      <div className="card-overlay">
        <div className="flex justify-end">
          <div className="py-10 px-10">
            <h1 className="text-primary text-2xl uppercase md:text-7xl pt-20 md:pt-36 pb-4  font-semibold max-w-lg mx-auto">
              CRYPTO NYMPHOS
            </h1>
            <p className="text-white text-xl font-bold uppercase max-w-lg mx-auto px-2">
              we have our own keys, and thats the way it should be....
            </p>
          </div>
        </div>
        <div className='flex justify-center'>
          <div className="absolute bottom-9">
            <div className="flex justify-center">
              <a className="text-white bg-primary px-10 py-4 rounded-full transition-all text-2xl hover:text-white font-semibold cursor-pointer"
               onClick={()=>{
                  wallet.connect()
              }}>
                {wallet.account?getShortWallet(wallet.account):"Connect Wallet"}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
