import React from "react";
import pic4 from "../assets/images/1278.png";
const WhoWeAre = () => {
  return (
    <div className="w-full bg-white">
      <div className="">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className=" px-4 py-4 md:px-20 flex self-center justify-center">
            <div>
              <h1 className=" text-5xl md:text-7xl text-primary font-bold pb-5 md:pb-10">
                who we are
              </h1>
              <p className="text-xl text-black font-normal">
                6969 Crypto Nymphos Leading The Female Empowerness movement 6969
                Crypto Nymphos The Female Empowerness movement 6969 Crypto
                Nymphos Leading The Female Empowerness Leading The Female
                Empowerness movement 6969 Crypto Nymphos Leading The Female
                Empowerness movement6969 Crypto Nymphos Leading The Female
                Empowerness movement
              </p>
            </div>
          </div>
          <div>
            <img src={pic4} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
