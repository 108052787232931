import React, {useState, useEffect, useCallback, useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Virtual, Navigation, Pagination} from "swiper/core";
import {FiChevronRight, FiChevronLeft} from "react-icons/fi";
import pic1 from "../assets/images/105.png";
import pic2 from "../assets/images/120.png";
import pic3 from "../assets/images/129.png";
import pic4 from "../assets/images/386.png";
import pic5 from "../assets/images/1124.png";
import pic6 from "../assets/images/1142.png";
import pic7 from "../assets/images/1193.png";
import pic8 from "../assets/images/1195.png";
import pic9 from "../assets/images/1278.png";
import pic10 from "../assets/images/2395.png";
import pic11 from "../assets/images/2612.png";
import pic12 from "../assets/images/4653.png";
import pic13 from "../assets/images/4967.png";
import pic14 from "../assets/images/5076.png";
import pic15 from "../assets/images/5714.png";

SwiperCore.use([Virtual]);
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);
const Welcome = () => {
  const swiperRef = useRef(null);

  const [currentIndex, updateCurrentIndex] = useState(0);
  const params = {
    initialSlide: 2,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    spaceBetween: 10,
    loop: true,
    autoplay: true
  };

  // Manipulate swiper from outside
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      updateIndex();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const updateIndex = useCallback(
    () => updateCurrentIndex(swiperRef.current.swiper.realIndex),
    []
  );
  // Add eventlisteners for swiper after initializing
  useEffect(
    () => {
      const swiperInstance = swiperRef.current.swiper;

      if (swiperInstance) {
        swiperInstance.on("slideChange", updateIndex);
      }

      return () => {
        if (swiperInstance) {
          swiperInstance.off("slideChange", updateIndex);
        }
      };
    },
    [updateIndex, swiperRef]
  );
  var sliderSettings = {
    150: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    440: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    680: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    // when window width is >= 1024
    1024: {
      slidesPerView: 3,
      spaceBetween: 10
    }
  };
  return (
    <div className="w-full bg-white">
      <div className="max-w-6xl mx-auto px-4 pt-20">
        <div className="flex justify-center">
          <div>
            <h1 className="text-primary text-4xl md:text-7xl font-semibold text-center pb-6">
              Polygon Network
            </h1>
            <p className="text-black text-lg md:text-3xl font-normal text-center ">
              Becasue GAS FEES Suck!
            </p>
          </div>
        </div>
        <div className="relative">
          <span
            onClick={goPrev}
            className="cursor-pointer transition-all  hover:shadow-sm absolute top-40 left-0 md:-left-5 z-50  "
          >
            <FiChevronLeft className="text-white w-12 h-12 p-1 bg-gray-500 rounded-full" />
          </span>
          <div className="mt-16  pb-20">
            <Swiper
              slidesPerView={3}
              navigation
              breakpoints={sliderSettings}
              {...params}
              ref={swiperRef}
            >
              <SwiperSlide>
                <div className="relative">
                  <img src={pic1} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic2} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic3} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic4} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic5} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic6} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic7} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic8} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic9} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic10} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic11} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic12} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic13} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic14} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative">
                  <img src={pic15} alt="" className="" />
                  <div className="card-overlay">
                    <p className="text-white text-3xl pl-4 pt-4 font-medium uppercase">
                      Code Matters
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <span
            onClick={goNext}
            className="cursor-pointer transition-all hover:shadow-sm absolute top-40 right-0 md:-right-5 z-50  "
          >
            <FiChevronRight className="text-white w-12 h-12 p-1 bg-gray-500 rounded-full" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
