import React from "react";
import Home from "./pages/Home";
import { useWallet, UseWalletProvider } from 'use-wallet'

function App() {
  return (
    <UseWalletProvider
    chainId={137}
    connectors={{
      // This is how connectors get configured
      portis: { dAppId: 'my-dapp-id-123-xyz' },
    }}
  >
    <Home />
  </UseWalletProvider>

  );
}

export default App;
