import React, {useState} from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import {GiHamburgerMenu} from "react-icons/gi";
import {useWallet} from "use-wallet";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };
  const wallet = useWallet()
  const getShortWallet = (address)=>{
    return `${address.substring(0,4)}....${address.slice(address.length - 4)}`;

  }

  return (
    <div>
      <div
        className="flex justify-between  z-50 h-20 fixed left-0 right-0 top-0 py-5 shadow-xl"
        style={{backgroundColor: "rgba(0, 0, 0, 0.9)"}}
      >
        <div className="pl-5 flex self-center">
          <a className =  "text-white">Logo</a>
        </div>
        <div className="pr-5 self-center cursor-pointer">
          <span onClick={toggleDrawer}>
            <GiHamburgerMenu className="w-8 h-8 text-white" />
          </span>
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        style={{backgroundColor: "rgba(0, 0, 0, 0.9)"}}
      >
        <div className="pl-5 pt-10 text-2xl text-white">Logo</div>
        <div className="flex flex-col pt-20 px-4">
          <ul className="flex flex-col">
            <li className="px-2.5 mb-5 flex justify-center">
              <a
                href="https://discord.gg/7UeHVbkn6H"
                className="text-primary w-full text-center bg-white px-5 py-2.5 rounded-full transition-all hover:text-primary font-semibold cursor-pointer"
              >
                Join Discord
              </a>
            </li>
            <li className="px-2.5 flex justify-center">
            <a className="text-white bg-primary px-10 py-4 rounded-full transition-all text-2xl hover:text-white font-semibold cursor-pointer"
               onClick={()=>{
                  wallet.connect()
              }}>
                {wallet.account?getShortWallet(wallet.account):"Connect Wallet"}
              </a>
            </li>
          </ul>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileMenu;
