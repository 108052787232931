import React from "react";
import Gallery from "../components/Gallery";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team";
import Welcome from "../components/Welcome";
import WhoWeAre from "../components/WhoWeAre";

const Home = () => {
  return (
    <div>
      <Layout>
        <div id="header">
          <Header />
        </div>
        <WhoWeAre />
        <Welcome />

        <Team />
        <div id="Gallery">
          <Gallery />
        </div>
        <Roadmap />
      </Layout>
    </div>
  );
};

export default Home;
